const size = {
	sml: '560px',
	med: '768px',
	lrg: '992px',
	xlrg: '1200px'
}
export const device = {
	sml: `(min-width: ${size.sml})`,
	med: `(min-width: ${size.med})`,
	lrg: `(min-width: ${size.lrg})`,
	xlrg: `(min-width: ${size.xlrg})`
};