import React, { useState } from "react"
import Img from "gatsby-image"
import styled from 'styled-components'
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from 'react-icons/io';

import { device } from '../common/Device';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import styles from "./style.module.scss"

const SliderWrapper = styled.div`
	.slick-track,
	.slick-list,
	.slick-slide {
		-webkit-perspective: 2000;
				perspective: 2000;
		-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
	}
	.slick-dots {
	    width: 100%;
	    margin: -4px 0 0;
	    padding: 10px;
	    text-align: center;
	    position: absolute;
	    bottom: 0;
	    li {
	        button {
	           border: 2px solid #fff;
	           border-radius: 50%;
	           &:hover {
		           background: #fff;
		       }
	           &:before {
		           content: '';
		       }
            }
            &.slick-active {
	            button {
		           background: #fff; 
		        }
	        }
	    }
	    @media ${device.med} { 
			padding: 30px 20px;
		}
	}
`

function NextArrow(props) {
	return (
		<div className={`${styles.arrow} ${styles.next}`}><IoIosArrowForward onClick={ props.onClick } /></div>
	)
}

function PrevArrow(props) {
	return (
		<div className={`${styles.arrow}`}><IoIosArrowBack onClick={ props.onClick } /></div>
	)
}

const SliderBlock = ( props ) => {

    const settings = {
		dots: true,
		infinite: true,
		lazyLoad: true,
		autoplay: false,
		speed: 0,
		fade: true,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />
    }
    
    const [isOpen, setOpen] = useState(false);
    
	return (
		<>
			<div className={ styles.block }
				bg={ props.hex }
				onClick={() => setOpen(!isOpen)}>
				<div className={ `${ styles.overlay }`}>
					{ props.text &&
						<h2 className={ styles.text }>{ props.text }</h2>
					}
					<div className={ styles.button }>View image gallery</div>
				</div>
				<Img className={ styles.background } 
					 fluid={ props.background } 
					 imgStyle={{objectPosition: props.position }}/>
			</div>
			{ isOpen &&
				<SliderWrapper className={ styles.sliderWrapper }>
					<div className={ styles.close } onClick={() => setOpen(!isOpen)}><IoIosClose /></div>
					<div className={ styles.width }>
						<div className={ styles.center }>
							<Slider className={ styles.slider } {...settings}>
								{ props.children }
							</Slider>
						</div>
					</div>
				</SliderWrapper>
			}
		</>
		
	)
}

export default SliderBlock