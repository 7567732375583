import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import FilterBlock from "../../../components/filter-block/"
import LinkBlock from "../../../components/link-block/"
import SliderBlock from "../../../components/slider-block/"
import ImageBlock from "../../../components/image-block/"
import TextBlock from "../../../components/text-block/"

import { psColour, wlColour } from "../../../data/data.colours"

const PDevPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			aston01: file(relativePath: { eq: "properties/aston41/aston41-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aston02: file(relativePath: { eq: "properties/aston41/aston41-02.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aston03: file(relativePath: { eq: "properties/aston41/aston41-03.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aston04: file(relativePath: { eq: "properties/aston41/aston41-04.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aston05: file(relativePath: { eq: "properties/aston41/aston41-interior-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aston06: file(relativePath: { eq: "properties/aston41/aston41-interior-02.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aston07: file(relativePath: { eq: "properties/aston41/aston41-interior-03.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			
			stables01: file(relativePath: { eq: "properties/the-stables/the-stables-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 3000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			stables02: file(relativePath: { eq: "properties/the-stables/the-stables-02.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			stables03: file(relativePath: { eq: "properties/the-stables/the-stables-03.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			stables04: file(relativePath: { eq: "properties/the-stables/the-stables-04.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			stables05: file(relativePath: { eq: "properties/the-stables/the-stables-05.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			stables06: file(relativePath: { eq: "properties/the-stables/the-stables-06.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			stables07: file(relativePath: { eq: "properties/the-stables/the-stables-07.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			
			musette01: file(relativePath: { eq: "properties/musette/musette-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			musette04: file(relativePath: { eq: "properties/musette/musette-04.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			musette05: file(relativePath: { eq: "properties/musette/musette-05.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			musette06: file(relativePath: { eq: "properties/musette/musette-06.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			musette07: file(relativePath: { eq: "properties/musette/musette-07.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			musette08: file(relativePath: { eq: "properties/musette/musette-08.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			
			crawley01: file(relativePath: { eq: "backgrounds/trucks-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	const [filter, setFilter] = useState(`All`);
	
	const filterFunction = (ref) => {
		filter === ref ? setFilter(`All`) : setFilter(ref)
	}
	
	const filterItems = [`All`, `Commercial`, `Residential`]
	
	return (
		<>
			<SEO title="Past Developments"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/w-lamb`}/>
				<Split>
					<FilterBlock 
						filterState={ filter }
						filterItems={ filterItems }
						filterFunction={ filterFunction }
						title={`Past developments`}
						titleTag={ `h1` }
						text={`
							<p>We have been building and developing land since 1986. A few of our past developments can be seen here but if you would like to know more please contact us.</p>
							<h2>Retained Property</h2>
							<p>Though most of our developments are sold, we retain a number of residential and commercial properties as investments. We have a growing portfolio of property under management - currently in excess of 200,000ft<sup>2</sup>, and our in-house team of dedicated professionals ensure they respond promptly to any tenant requests.</p>
						`}
						bgColour={`blue`}/>

					<Split vertical={true}>
						{ (filter === `All` || filter === `Commercial`) &&
							<SliderBlock
								hex={wlColour.hex}
								text={`Aston 41 (Phase 1), Aston Clinton`}
								background={ data.aston05.childImageSharp.fluid }
								position={`100% 40%`}>
								<ImageBlock 
									image={ data.aston01.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.aston02.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.aston03.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.aston04.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.aston05.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.aston06.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.aston07.childImageSharp.fluid }/>
							</SliderBlock>
						}
						{ (filter === `All` || filter === `Residential`) &&
							<SliderBlock
								hex={wlColour.hex}
								text={`The Stables, Hastoe, Hertfordshire`}
								background={ data.stables01.childImageSharp.fluid }>
								<ImageBlock 
									image={ data.stables01.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.stables02.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.stables03.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.stables04.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.stables05.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.stables06.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.stables07.childImageSharp.fluid }/>
							</SliderBlock>
						}
						{ (filter === `All` || filter === `Commercial`) &&
							<SliderBlock
								hex={wlColour.hex}
								text={`Musette, Church Farm`}
								background={ data.musette01.childImageSharp.fluid }>
								<ImageBlock 
									image={ data.musette01.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.musette04.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.musette05.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.musette06.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.musette07.childImageSharp.fluid }/>
								<ImageBlock 
									image={ data.musette08.childImageSharp.fluid }/>
							</SliderBlock>
						}
						{ (filter === `All` || filter === `Commercial`) &&
							<TextBlock 
								title={`Crawley Crossing, Bedfordshire`}
								titleTag={ `h3` }
								text={`
									<h4>Image gallery coming soon</h4>
								`}
								bgImage={ data.crawley01.childImageSharp.fluid }
								position={`50% 40%`}/>
						}
					</Split>
				</Split>
			</Layout>
		</>
	)
}

export default PDevPage
